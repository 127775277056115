
export const API_CONFIG = {
    
    //apiURL: "https://vestibular.souzamarques.br:8443/api-vestibular", //"https://vestibular.souzamarques.br:8443/api-sitesm",  
    apiURL: "https://hmgvest.souzamarques.br:8443/api-vestibular",    
    //apiURL: "http://localhost:8080", //"http://192.168.100.155:8080", 
    
    iisVirtualDirectoryPath: "C:/docs/site/", 
    
    //iisVirtualDirectoryURL: "https://souzamarques.br/site-files/", 
    iisVirtualDirectoryURL: "https://hmgsite.souzamarques.br/site-files/", 
    //iisVirtualDirectoryURL: "http://localhost:9090/site-files/",//"http://192.168.100.155:9090/site-files/",
        
    tokenMoodle: "04087f1102a11954da9c82ffc8afe82f", //prod
    //tokenMoodle: "eac1e9b11bbf9517472eb311285af720", //hmg

    apiMoodle: "/apimoodle",
    

}